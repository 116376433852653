.mbody {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    text-align: center;
    background-color: #f9f5f0;
}
.mheader {
    padding-top: 50px;
}
.mheader img {
    width: 50px;
}
.mheader h1 {
    font-size: 36px;
    margin: 10px 0;
}
.mheader p {
    font-size: 18px;
    color: #555;
}
.members {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 50px 0;
}
.member {
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin:  20px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    min-width: 200px;
    align-items: center;
    /* width: 35%; */
    
}
.member img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    /* object-position: left   ; */
    /* height: auto; */
}
.member-info {
    padding: 20px;
    position: relative;
}
.member-info h3 {
    font-size: 20px;
    margin: 0;
}
.member-info p {
    color: #777;
    font-size: 16px;
    margin: 5px 0 0;
}
.member-info .tag {
    background-color: #4CAF50;
    color: white;
    padding: 5px 10px;
    position: absolute;
    bottom: 20px;
    left: 20px;
    border-radius: 5px;
}
.member-info .tag.yellow {
    background-color: #FFC107;
}

@media only screen and (max-width: 768px){
    .member{
       flex-direction: column;
       justify-content: center;
       align-items: center;
    }
    .member img{
    border-radius: 50%;
    height: 150px;
    width: 150px;
    margin-top: 15px;
   
    }
    .p{
        padding: 0 !important;
    }
       }