.pbody {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #ffffff;
    color: #333333;
}
.pcontainer {
    text-align: center;
    width: 70%;
    padding:50px 0; 
    position: relative;
    margin:auto;
}
.header {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}
.subheader {
    font-size: 20px;
    /* font-weight: bold; */
    margin-bottom: 50px;
}
.process {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
}
.process-item {
    /* width: 200px; */
    margin: 20px;
    text-align: center;
}
.process-item img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}
.process-item h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0;
}
.process-item p {
    font-size: 14px;
    color: #666666;
}
.center-image {
    width: 300px;
    height: auto;
    margin: 20px;
}
.dashed-line {
    position: absolute;
    width: 60%;
    height: 60%;
    border: 2px dashed #f0c040;
    border-radius: 50%;
    top: 20%;
    left: 20%;
    z-index: -1;
}
.vertical-text {
    writing-mode: vertical-rl;
    text-orientation: mixed;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 12px;
    color: #666666;
}
@media only screen and (max-width:768px) {
    .remove{
        display:none;
    }
}