body {
    font-family: 'Roboto', sans-serif;
    background-color: #f9f5f0;
    margin: 0;
    padding: 0;
}
.scontainer {
    text-align: center;
    margin: 50px auto;
    /* padding: 50px; */
}
.scontainer h1 {
    font-size: 36px;
    margin: 10px 0;
}
.scontainer h2 {
    font-size: 24px;
    color: #6c757d;
    margin: 0;
}
.sservices {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 30px;
    
}
.sservice-item {
    width: 300px;
    text-align: center;
}
.sservice-item img {
    width: 100%;
    border-radius: 10px;
    border: 2px solid black ;

}
.sservice-item p {
    font-size: 16px;
    margin-top: 10px;
}

@media only screen and (max-width: 768px){
.sservices{
    flex-direction: column;
    width: 88% !important;
}
.scontainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}
}
