.fbody {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    background: url("./footerbg.png") no-repeat center center fixed;
    background-size: cover;
    color: #fff;
}
.newsletter {
    text-align: center;
    padding: 50px 0;
}
.newsletter h1 {
    font-size: 36px;
    margin-bottom: 20px;
    color: #04561a;
}
.newsletter input[type="email"] {
    padding: 10px;
    font-size: 16px;
    border: none;
    background-color: white;
    color: #000;
    border-radius: 5px;
    width: 300px;
    margin-right: 10px;
    margin-bottom: 5px;
}
.newsletter input[type="text"] {
    padding: 10px;
    font-size: 16px;
    border: none;
    background-color: white;
    color: #000;
    border-radius: 5px;
    width: 300px;
    margin-right: 10px;
    margin-bottom: 5px;

}
.newsletter textarea{
    padding: 10px;
    font-size: 16px;
    border: none;
    background-color: white;
    color: #000;
    border-radius: 5px;
    width: 300px;
    margin-right: 10px;
    margin-bottom: 5px;

}
.newsletter button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    background-color: #3a763c;
    color: white;
    cursor: pointer;
}
.Company:hover{
color: rgb(97, 233, 233) !important;
}
.footer {
    background-color: rgba(0, 0, 0, 0.7);
    padding-top: 50px;
}
.footer .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}
.footer .column {
    flex: 1;
    margin: 0 20px;
}
.footer .column h3 {
    font-size: 18px;
    margin-bottom: 20px;
}
.footer .column p, .footer .column ul {
    font-size: 14px;
    line-height: 1.6;
}
.footer .column ul {
    list-style: none;
    padding: 0;
}
.footer .column ul li {
    margin-bottom: 10px;
}
.footer .column ul li a {
    color: #fff;
    text-decoration: none;
}
.footer .column ul li a:hover {
    text-decoration: underline;
}
.footer .social-icons {
    display: flex;
    gap:15px;
    margin-top: 20px;
}
.footer .social-icons a {
    color: #fff;
    font-size: 20px;
    margin-right: 10px;
    text-decoration: none;
}
.footer .gallery img {
    width: 70px;
    height: 70px;
    margin: 5px;
    border-radius: 5px;
}
.footer .copyright {
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
}
.back-to-top {
    position: fixed;
    right: 20px;
    bottom: 20px;
    font-size: 14px;
    text-transform: uppercase;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    color: #fff;
    cursor: pointer;
}