.header-h1{
    margin: 0;
    line-height: 45px;
    animation: move 0.5s ease-in-out;
    font-size: 40px;
    color: #fff;
  
  }
  
  @keyframes move {
    0% {
      transform: translateX(7%);
      opacity: 0;
    }
    70% {
      transform: translateX(-1%);
      opacity: 1;
    }
    100% {
      transform: translateX(0);
    }
  }
  
  .header-p{
    margin: 20px 0 0 0;
    animation: move 1s ease-in-out;
    color: #fff;
  }
  
  .header-img{
    width: 90%;
    animation: moveimg 1s ease-in-out;
  }
  
  @keyframes moveimg {
    0% {
      transform: translateX(-7%);
      opacity: 0;
    }
    70% {
      transform: translateX(1%);
      opacity: 1;
    }
    100% {
      transform: translateX(0);
    }
  }


  @media only screen and (max-width: 768px){
  .header-h1{
    line-height: normal;
  }
  .header-box{
    flex-direction: column;
  }
  .header-box div:nth-of-type(1){
    width: auto !important;
  }

}