@media only screen and (max-width: 768px){
    .column{
        flex-direction: column;
        /* width: 88% !important; */
    }
    .column-reverse{
        flex-direction: column;
        /* width: 88% !important; */
    }
    .w100{
        width: 100vw !important;
        display: none;
    }

        
    }