.cbody {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
}
.ccontainer {
    display: flex;
    padding: 0px 10%;
    /* max-width: 1200px; */
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.cleft-section {
    background-color: #ffffff;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.cleft-section img {
    border-radius: 8px;
    max-width: 100%;
    height: auto;
}
.cright-section {
    padding: 30px;
    flex: 1;
}
.cright-section h2 {
    font-size: 36px;
    margin: 0;
    color: #333;
}
.cright-section h2 span {
    color: #6c8e3d;
}
.cright-section p {
    color: #666;
    line-height: 1.6;
}
.features {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
}
.feature {
    flex: 1;
    min-width: 200px;
    margin: 10px 20px;
    display: flex;
    align-items: center;
}
.feature i {
    font-size: 24px;
    margin-right: 10px;
    color: #BB9E03;
}
.feature p {
    margin: 0;
    color: #666;
}
.feature p span {
    display: block;
    font-weight: bold;
    color: #333;
}
.button {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #BB9E03;
    color: white;
    text-decoration: none;
    border-radius: 4px;
}

@media only screen and (max-width: 768px){
    .ccontainer{
        flex-direction: column;
        width: 88% !important;
    }  
    }