body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #ffffff;
}
.container {
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    margin: 0 auto;
}
.content {
    flex: 1;
    padding: 20px;
}
.content h1 {
    font-size: 48px;
    margin: 0;
}
.content p {
    font-size: 16px;
    color: #666666;
}
.content .section {
    display: flex;
    align-items: center;
    margin-top: 20px;
    
}
.content .section img {
    width: 80px;
    height: 80px;
    margin-right: 20px;
}
.content .section h3 {
    font-size: 20px;
    margin: 0;
}
.content .section p {
    margin: 5px 0 0 0;
}
.image-section {
    flex: 1;
    position: relative;
    text-align: end;
}
.image-section img {
    width: 95%;
    height: auto;
}
.image-section .organic-badge {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #4CAF50;
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 50%;
    font-size: 24px;
    font-weight: bold;
}
.small-image {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 50px;
    height: auto;
    border: 5px solid #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 768px){
.container{
    flex-direction: column;
    width: 88% !important;
}
.small-image{
    width: 200px !important;
    height: 200px !important;
}
    
}