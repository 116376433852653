.logo-img{
  height: 50px;
  animation: moveimg 1s ease-in-out;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 30px;
    background: #fff;
    z-index: 5;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  }
  .navbar .logo {
    font-size: 1.5em;
    font-weight: 700;
  }
  .navbar .menu {
    display: flex;
    align-items: center;
  }
  .navbar .menu a {
    text-decoration: none;
    color: #000;
    margin: 0 15px;
    font-size: 1em;
    animation: ania 1s ease-in-out;
  }
  .navbar .menu a:hover {
    color: #007BFF;
  }
  .navbar .menu .btn {
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    color: #000000;
    border: 2px solid #000;
    transition: 0.3s ease-in-out;
    margin-left: 15px;
  }
  .navbar .menu .btn:hover{
    color: #ffffff;
    background: #000;
  }
  
  .btn {
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    color: #000000;
    border: 2px solid #000;
    transition: 0.3s ease-in-out;
    margin-left: 15px;
  }
  
  .btn:hover{
    color: #ffffff;
    background: #000;
  }
  
  
  @keyframes ania {
    0% {
      transform: translateX(10%);
      opacity: 0;
    }
    70% {
      transform: translateX(-1%);
      opacity: 1;
    }
    100% {
      transform: translateX(0);
    }
  }
  .Mobile{
    display: none;
  }
  .mobile-navbar{
    transition: show 2s ease-in-out;
    display:flex;
    justify-Content:center;
    align-Items:center;
    position: absolute; 
    background: white;
    width: 100%;
    z-index: 1;
    opacity: 0;
  }
  .mobile-navbar ul{
    transition: transform 3s ease-in-out;
    text-transform:uppercase;
    padding:0;
    display:flex;
    flex-direction:column;
    list-Style-Type:none;
    justify-Content:center;
    align-Items:center;
    gap:20px;
    font-weight: 500;
  }
  .hide-menu{
    animation: hide 1s !important;
    opacity: 0;
  }
  .display{
    animation: show 1s;
    opacity: 1;
  }
  @keyframes show {
    0% {transform: translateY(-100%);opacity: 0;}
    100% {transform: translateY(0%);opacity:1}
  }
  @keyframes hide {
    0% {transform: translateY(0%);opacity: 1;}
   100% {transform: translateY(-100%);opacity: 0;}
  }

  @media only screen and (max-width: 768px){
    .Desktop{
      display: none;
    }
    .Mobile{
      display: Block;
    }
    .btn{
      margin-left: 0 !important;
    }
  }
  