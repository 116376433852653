.obody {
    font-family: 'Nunito', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #fff;
    color: #333;
}
.ocontainer {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    padding: 50px 0;
}
.oheader {
    margin-bottom: 50px;
}
.oheader img {
    width: 50px;
}
.oheader h2 {
    font-size: 36px;
    margin: 10px 0;
}
.testimonials {
    display: flex;
    justify-content: space-between;
}
.testimonial {
    background-color: #f9f3f3;
    border-radius: 10px;
    padding: 20px;
    width: 45%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative;
}
.testimonial:nth-child(2) {
    background-color: #f0f8f4;
}
.testimonial img {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    object-fit: cover;
    margin-bottom: 20px;
}
.testimonial .quote {
    color: #4CAF50;
    font-size: 24px;
}
.testimonial .stars {
    color: #FFD700;
    margin: 10px 0;
}
.testimonial h3 {
    font-size: 24px;
    margin: 10px 0;
}
.testimonial p {
    color: #4CAF50;
    font-size: 16px;
    line-height: 1.5;
}
.testimonial .customer {
    display: flex;
    margin-top: 20px;
}
.testimonial .customer img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}
.testimonial .customer .name {
    font-weight: bold;
}
.testimonial .customer .role {
    color: #FFA500;
}
.back-to-top {
    position: absolute;
    right: 20px;
    bottom: 20px;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    color: #333;
    font-size: 14px;
    text-decoration: none;
}
        
@media only screen and (max-width: 768px){
    .testimonials{
        flex-direction: column;
    }
    .testimonial{
        width: auto;
        margin-bottom: 20px;
    }
        
}